/*! Michael Pumo - Web Development. http://michaelpumo.com */

(function($) {

    'use strict';

    var cache = {

        $images: $('.image')

    };

    var app = {

        init: function() {

            app.images();

        },

        images: function() {

            if( cache.$images.length ) {

                cache.$images.each(function() {
                    var $image = $(this);
                    $('#' + $image.attr('id') ).backstretch($image.data('image'));
                    console.log($image);
                });

            }

        }

    };

    $(function() {
        
        app.init();
    
    });

})(jQuery);
